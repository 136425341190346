<template>
    <div class="row">
        <div class="col">
            <div class="card grid-margin">
                <div class="card-body">
                    <h1 class="card-title">
                        <i class="mdi mdi-account-check"></i>
                        Candidates
                    </h1>

                    <p class="card-description">The candidates that participates in elections</p>

                    <div class="row">
                        <div class="col-12">
                            <div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
                                <div class="row">
                                    <div class="col-xs-12 col-4">
                                        <button type="button" class="btn btn-primary" @click="addCandidate">
                                            <i class="mdi mdi-plus"></i>
                                            Add Candidate
                                        </button>
                                    </div>

                                    <div class="col-xs-12 col-8">
                                        <toolbar
                                            @show="val => params.per_page = val"
                                            @search="val => params.keyword = val" />
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        <template v-if="candidates.length">
                                            <div class="table-responsive">
                                                <candidates-table
                                                    @edit="editCandidate"
                                                    :candidates="candidates"
                                                    @delete="deleteCandidate" />
                                            </div>

                                            <app-pagination
                                                v-if="pageDetails && pageDetails.total"
                                                :pageDetails="pageDetails"
                                                @navigate="getCandidates($event)" />
                                        </template>

                                        <template v-else>
                                            <p class="lead mt-5 mb-5 text-center">There are no candidates in the system.</p>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

		<modal
			:show="showModal"
            :candidate="modalCandidate"
            @save-candidate="saveCandidate"
			@update:show="val => showModal = val" />
    </div>
</template>

<script>
    import Modal from './Modal';
    import { mapActions, mapGetters } from 'vuex';
    import CandidatesTable from './Table';

    export default {
        components: {
            Modal,
            CandidatesTable,
        },

        data() {
            return {
                showModal: false,
                modalCandidate: {},
                params: {
                    paginate: true,
                    per_page: null,
                    keyword: null
                }
            };
        },

        computed: {
            ...mapGetters({
                candidates: 'Candidates/getAll',
                pageDetails: 'Candidates/getPageDetails'
            })
        },

        watch: {
            params: {
                deep: true,
                handler() {
                    this.getCandidates();
                }
            }
        },

		methods: {
			...mapActions({
                load: 'Candidates/all',
                store: 'Candidates/store',
                update: 'Candidates/update',
                delete: 'Candidates/delete',
                loadConstituencies: 'Constituencies/all',
                loadParties: 'Parties/all'
            }),

            /**
             * Open the add candidate modal.
             *
             * @return {Undefined}
             */
            addCandidate() {
                this.showModal = true;
            },

            /**
             * Open the edit candidate modal.
             *
             * @param {Object} candidate
             * @return {Undefined}
             */
            editCandidate(candidate) {
                this.showModal = true;
                this.modalCandidate = { ...candidate };
            },

            /**
             * Get all candidates from the server.
             *
             * @param {Object} query
             * @return {Undefined}
             */
            getCandidates(query = {}) {
                for (let param of Object.keys(this.params)) {
                    if (this.params[param]) {
                        query[param] = this.params[param];
                    }
                }

                this.load(query)
                    .catch(errors => this.notify(this.buildErrors(errors), 'error'));
            },

            /**
             * Save the candidate in the backend.
             *
             * @param {Object} candidate
             * @return {Undefined}
             */
            saveCandidate(candidate) {
                let keyword = 'added';
                let savingMethod = this.store;
                let payload = { data: new FormData() };

                if (candidate.id !== 'undefined' && candidate.id) {
                    keyword = 'updated';
                    payload.id = candidate.id;
                    savingMethod = this.update;
                }

                payload.data.append('name', candidate.name);
                payload.data.append('type', candidate.type);
                payload.data.append('party_id', candidate.party_id);
                payload.data.append('constituency_id', candidate.constituency_id);

                if (candidate.photo) {
                    payload.data.append('photo', candidate.photo);
                }

                savingMethod(payload)
                    .then(response => {
                        this.notify(`The candidate was ${keyword} successfully.`);
                        this.getCandidates();
                        this.showModal = false;
                        this.modalCandidate = {};
                    })
                    .catch(errors => this.notify(this.buildErrors(errors), 'error'));
            },

			/**
			 * Delete the specified candidate.
             * Request for a deletion confirmation.
			 *
			 * @param  {Number} id
			 * @return {Undefined}
			 */
			deleteCandidate(id) {
				Swal({
					title: "Are you sure?",
					text: "Once deleted, you will not be able to recover this candidate and its data!",
					type: "warning",
                    showCancelButton: true,
                    confirmButtonText: 'Yes - Delete it.',
                    confirmButtonClass: 'bg-danger',
                    cancelButtonText: 'No - Cancel Delete'
				})
				.then(response => {
					if (response.value) {
						this.delete(id)
							.then(() => {
								this.notify('The candidate was deleted successfully.');
								this.getCandidates();
							})
							.catch(errors => this.notify(this.buildErrors(errors), 'error'))
					}
				});
            },

            /**
             * Get constituencies into state
             *
             * @return {Undefined}
             */
            getConstituencies() {
                return this.loadConstituencies({})
                    .then(() => {
                        return Promise.resolve();
                    })
                    .catch(errors =>  {
                        this.notify(this.buildErrors(errors), 'error');

                        return Promise.reject();
                    });
            },

            /**
             * Load parties into state
             */
            getParties() {
                return this.loadParties({})
                    .then(Promise.resolve())
                    .catch(errors =>  {
                        this.notify(this.buildErrors(errors), 'error');

                        return Promise.reject();
                    });
            }
        },

        mounted() {
            this.getCandidates();
            this.getConstituencies();
            this.getParties();
        }
    }
</script>

<style lang="scss" scoped>
</style>


