<template>
    <modal
        :force="true"
        :show="show"
        :title="title"
        :ok-text="title"
        :close-when-cancel="true"
        @cancel="$emit('update:show', false)"
        @ok="$emit('save-candidate', payload)"
        @update:show="$emit('update:show', $event)">
			<div class="row justify-content-center">
				<div class="col-xs-12 col-10">
                    <div class="form-group text-center">
                        <img class="img-thumbnail" :src="photoSrc">

                        <br><br>

                        <input
                            type="file"
                            name="file"
                            accept="image/*"
                            ref="uploadField"
                            @change="uploadImage">

                        <button
                            type="button"
                            class="btn btn-info btn-fill upload-btn"
                            @click="initImageUpload">
                                Change Image
                        </button>
                    </div>

                    <div class="form-group" :class="nameClass">
                        <label for="name">Name</label>
                        <input
                            id="name"
                            type="text"
                            name="name"
                            v-model="payload.name"
                            class="form-control"
                            v-validate="rules.name"
                            placeholder="Candidate's Name">

                        <error-label :message="errors.first('name')" />
                    </div>

                    <div class="form-group">
                        <label for="party_id">Party</label>
                        <select
                            id="party_id"
                            ref="party"
                            class="select-2 form-control"
                            data-placeholder="Select A Party">
                                <option value="">Select A Party</option>

                                <option v-for="(party, index) in parties"
                                    :value="party.id"
                                    :key="index">{{ party.name }}</option>
                        </select>
                    </div>

                    <div class="form-group">
                        <label for="type">Type</label>
                        <select
                            id="type"
                            ref="type"
                            class="select-2 form-control"
                            data-placeholder="Select a candidate type">
                            <option value="">Select a candidate type</option>
                            <option value="parliamentary">Parliamentary</option>
                            <option value="presidential">Presidential</option>
                        </select>
                    </div>

                    <div class="form-group" v-show="payload.type == 'parliamentary'">
                        <label for="constituency_id">Constituency</label>
                        <select
                            id="constituency_id"
                            ref="constituency"
                            class="select-2 form-control"
                            data-placeholder="Select A Constituency">
                                <option value="">Select A Constituency</option>

                                <option v-for="(constituency, index) in constituencies"
                                    :value="constituency.id"
                                    :key="index">{{ constituency.name }}</option>
                        </select>
                    </div>
				</div>
			</div>
    </modal>
</template>

<script>
    import { mapGetters } from 'vuex';

	export default {
		props: {
            show: {
                type: Boolean,
                default: false
            },

            candidate: {
                type: Object,
                default: {}
            }
        },

		data() {
			return {
                payload: {
                    name: '',
                    photo: '',
                    type: '',
                    party_id: '',
                    constituency_id: ''
                },

                previewImage : '',

                rules: {
                    name: 'required|min:2'
                }
			};
        },

        computed: {
            ...mapGetters({
                parties: 'Parties/getAll',
                constituencies: 'Constituencies/getAll'
            }),

            /**
             * Get the photo source.
             * When there is no photo display placeholder.
             *
             * @return {String}
             */
            photoSrc() {
                if (this.payload.photo) {
                    return this.previewImage ? this.previewImage : this.payload.photo;
                }

                return this.placeholder;
            },

            /**
             * Check if the current operation is an update.
             *
             * @return {Boolean}
             */
            isUpdate() {
                return Object.keys(this.candidate).length > 0;
            },

            /**
             * Compute the has danger class for the name field.
             *
             * @return {Object}
             */
            nameClass() {
                return { 'has-danger': this.errors.has('name') };
            },

            /**
             * Title for the modal depending on the current operation.
             *
             * @return {String}
             */
            title() {
                return this.isUpdate ? 'Edit Candidate' : 'Add Candidate';
            }
        },

		watch: {
            /**
             * Toggle the id property depending on the save operation. Update or Save.
             *
             * @param {Boolean} value
             * @return {Undefined}
             */
			show(value) {
                this.resetModal();

                if (this.isUpdate) {
                    this.getCandidateDetails();
                    this.$set(this.payload, 'id', this.candidate.id);
                } else {
                    this.$delete(this.payload, 'id');
                }
			}
		},

		methods: {
			/**
			 * Trigger image upload by clicking on the hidden file field.
			 *
			 * @return {Undefined}
			 */
			initImageUpload() {
				const fileField = this.$refs.uploadField;

				if (fileField) {
					fileField.click();
				}
			},

			/**
			 * Set the value of the field to the uploaded image.
			 * Return false if no file has been selected.
			 *
			 * @param  {Object} event
			 * @return {Undefined}
			 */
			uploadImage(event) {
				let files = event.target.files;

				if (!files) {
					this.notify(this.buildErrors({
                        error: 'No or invalid file to be uploaded.'
                    }), 'error');

					return;
                }

                this.payload.photo = files[0];
				this.getImagePreview(event.target);
			},

			/**
			 * Get the preview of the uploaded image.
			 *
			 * @param  {Object} element
			 * @return {Undefined}
			 */
			getImagePreview(element) {
				getPreviewUrl(element)
					.then(url => this.previewImage = url)
					.catch(error => this.notify(this.buildErrors(error), 'error'));
            },

            /**
             * Get the details of the candidate to be updated.
             *
             * @return {Undefined}
             */
            getCandidateDetails() {
                this.payload.name = this.candidate.name;
                this.payload.photo = this.candidate.photo;
                $(this.$refs.party).val(this.candidate.party_id).change();
                $(this.$refs.constituency).val(this.candidate.constituency_id).change();
                $(this.$refs.type).val(this.candidate.type).change();
            },

            /**
             * Reset the modal.
             *
             * @return {Undefined}
             */
            resetModal() {
                this.previewImage = '';
                this.payload.name = '';
                this.payload.photo = '';

                this.$nextTick(() => this.errors.clear());
            },

            /**
             * Set the party
             *
             * @return {Undefined}
             */
            setParty(event) {
                this.payload.party_id = event.target.value;
            },

            setConstituency(event) {
                this.payload.constituency_id = event.target.value;
            },

            setType(event) {
                this.payload.type = event.target.value;
            },

            /**
             * Register event listeners for select 2.
             *
             * @return {Undefined}
             */
            registerEventListeners() {
                $(this.$refs.party).on('change', this.setParty);
                $(this.$refs.constituency).on('change', this.setConstituency);
                $(this.$refs.type).on('change', this.setType);
            },
        },

        mounted() {
            this.registerEventListeners();
        }
	}
</script>

<style scoped>
    input[type=file] {
        display: none;
    }

    img.img-thumbnail {
        height: 200px;
        max-width: 350px;
    }
</style>
