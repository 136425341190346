<template>
    <table class="table dataTable table-striped no-footer" role="grid">
        <thead>
            <tr role="row">
                <th></th>
                <th class="sorting">Name</th>
                <th>Type</th>
                <th>Party</th>
                <th class="sorting">Elections</th>
                <th class="sorting">Created At</th>
                <th class="sorting">Updated At</th>
                <th></th>
            </tr>
        </thead>

        <tbody>
            <tr role="row" v-for="(candidate, index) in candidates" :key="index">
                <td class="py-1"><img :src="photoSrc(candidate.photo)"></td>
                <td>{{ candidate.name }}</td>
                <td>{{ candidate.type | capitalize }}</td>
                <td>{{ candidate.party.name }}</td>
                <td>{{ candidate.elections.length }} </td>
                <td>{{ candidate.created_at | formattedDateTime }}</td>
                <td>{{ candidate.updated_at | formattedDateTime }}</td>
                <td>
                    <button class="btn btn-warning btn-action mr-3" @click="$emit('edit', candidate)" data-toggle="popover" data-content="Edit Candidate">
                        <i class="mdi mdi-pencil"></i>
                    </button>

                    <button class="btn btn-danger btn-action" @click="$emit('delete', candidate.id)" data-toggle="popover" data-content="Delete Candidate">
                        <i class="mdi mdi-trash-can"></i>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
    export default {
        props: ['candidates'],

        methods: {
            photoSrc(photo) {
                return photo ? photo : this.placeholder;
            }
        }
    }
</script>